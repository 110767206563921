import React from 'react';
import Layout from '../components/layout/layout';
import HeroPricing from '../components/pricing/hero'; 

export default function PricingPage({ initialData }) {
  return (
    <Layout initialData={initialData}>
      <HeroPricing />
    </Layout>
  );
}