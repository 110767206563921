import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export default function Pricing() {
  const [isAnnual, setIsAnnual] = useState(false);
  const [showDeadstock, setShowDeadstock] = useState(false);

  const deadstockPlans = [
    {
      name: "Monthly",
      icon: "📅",
      price: 500,
      period: "month",
      isFree: false,
      isPopular: false,
      description: "Smart inventory management and redistribution platform for your business",
      features: [
        "Unlimited Products",
        "Smart Inventory Analysis",
        "Access to Retailer Network",
        "Real-time Performance Tracking",
        "Free POS System",
        "Priority Support",
        "Custom Integrations"
      ]
    },
    {
      name: "Quarterly",
      icon: "🗓️",
      price: 1350,
      period: "3 months",
      freeDays: 9,
      isPopular: true,
      description: "Smart inventory management and redistribution platform for your business",
      features: [
        "Everything in Monthly +",
        "Advanced Analytics",
        "Dedicated Account Manager",
        "Custom Reports",
        "API Access",
        "Premium Integrations",
        "24/7 Support"
      ]
    },
    {
      name: "Half Yearly",
      icon: "📊",
      price: 2650,
      period: "6 months",
      freeDays: 21,
      description: "Smart inventory management and redistribution platform for your business",
      features: [
        "Everything in Quarterly +",
        "White-label Solution",
        "Custom Development Hours",
        "Advanced Data Exports",
        "Multiple Store Management",
        "Training Sessions",
        "SLA Guarantees"
      ]
    },
    {
      name: "Annual",
      icon: "🎯",
      price: 5000,
      period: "year",
      freeDays: 60,
      description: "Smart inventory management and redistribution platform for your business",
      features: [
        "Everything in Half Yearly +",
        "Enterprise Support",
        "Custom Feature Development",
        "Dedicated Server",
        "Multi-branch Support",
        "Annual Strategy Review",
        "Priority Feature Requests"
      ]
    }
  ];

  const socialPlans = [
    {
      name: "Basic",
      icon: "🎯",
      price: 1000,
      description: "Perfect for individuals and micro-businesses starting their WhatsApp journey",
      features: [
        "1 Seat",
        "Up to 500 Contacts",
        "Customer Segmentation",
        "Basic Storefront",
        "3 Automations",
      ]
    },
    {
      name: "Startup",
      icon: "💬",
      price: 5000,
      description: "Perfect for small businesses looking to grow with WhatsApp commerce",
      features: [
        "2 Seats",
        "Up to 5,000 Contacts",
        "Customer Segmentation",
        "Online Storefront",
        "Basic Support",
        "10 Automations"
      ]
    },
    {
      name: "Growth",
      icon: "🚀",
      price: 15000,
      description: "Ideal for growing businesses needing more features and automation",
      isPopular: true,
      features: [
        "5 Seats",
        "Up to 20,000 Contacts", 
        "Customer Segmentation",
        "Online Storefront",
        "Priority Support",
        "250 Automations",
        "Month-Long Customizations"
      ]
    },
    {
      name: "Corporate",
      icon: "🏢",
      description: "Advanced features and support for large-scale operations",
      isEnterprise: true,
      features: [
        "25 Seats",
        "Unlimited Contacts",
        "Customer Segmentation",
        "Online Storefront",
        "Tier 1 Engineering Support",
        "Automations",
        "Lifetime Customizations",
        "WhatsApp Business Catalog Integration"
      ]
    }
  ];
  const renderPrice = (plan) => {
    if (plan.isEnterprise) {
      return <span className="text-2xl font-medium text-gray-600">Custom Pricing</span>;
    }
   
    if (showDeadstock) {
      return (
        <div className="space-y-1">
          <div className="flex items-baseline gap-1">
            <span className="text-4xl font-bold">KES {plan.price}</span>
            <span className="text-gray-600">/{plan.period}</span>
          </div>
          {plan.freeDays && (
            <p className="text-sm text-primary font-bold">{plan.freeDays} days free included</p>
          )}
        </div>
      );
    }
   
    return (
      <div className="space-y-1">
        <div className="flex items-baseline gap-1">
          <span className="text-4xl font-bold">KES {plan.price}</span>
          <span className="text-gray-600">/month</span>
        </div>
        {!showDeadstock && isAnnual && (
          <p className="text-sm text-primary font-bold">Get 1 month free with annual billing</p>
        )}
      </div>
    );
  };

  const handleEnterpriseClick = () => {
    window.location.href = "tel:+254703867693";
  };

  const activePlans = showDeadstock ? deadstockPlans : socialPlans;

  return (
    <section className="md:py-24">
      {/* Toggle Switch */}
      <div className="fixed top-24 right-4 flex items-center gap-3 bg-white/80 backdrop-blur-sm p-2 rounded-full shadow-md z-50">
        <span className={`text-sm transition-colors duration-300 ${!showDeadstock ? 'text-primary font-medium' : 'text-gray-500'}`}>
          Social Commerce
        </span>
        <button
          onClick={() => setShowDeadstock(!showDeadstock)}
          className="relative w-14 h-7 rounded-full transition-colors duration-300"
          style={{
            backgroundColor: showDeadstock ? '#7E22CE' : '#F3F4F6'
          }}
        >
          <div
            className="absolute top-1 left-1 w-5 h-5 bg-white rounded-full shadow-md transition-transform duration-300 ease-in-out"
            style={{
              transform: showDeadstock ? 'translateX(28px)' : 'translateX(0)'
            }}
          />
        </button>
        <span className={`text-sm transition-colors duration-300 ${showDeadstock ? 'text-primary font-medium' : 'text-gray-500'}`}>
          Deadstock Solution
        </span>
      </div>

      <div className="container mx-auto px-4 md:px-8">
        <div className="text-center max-w-4xl mx-auto mb-16">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6">
            {showDeadstock ? "Smart Solution for" : "Unlock Growth with"}
            <span className="text-primary block mt-2">
              {showDeadstock ? "Your Deadstock" : "Flexible Pricing"}
            </span>
          </h1>
          <p className="text-gray-600 text-lg md:text-xl leading-relaxed">
            {showDeadstock 
              ? "Transform your excess inventory into opportunity with our intelligent platform. Simple pricing, powerful features."
              : "From micro-businesses to established enterprises, we scale with your ambitions. Choose a plan that matches your business needs."}
          </p>
        </div>
        
        <div className="max-w-xl mx-auto text-center mb-12">
          <div className="inline-flex items-center bg-white rounded-full p-2 shadow-sm">
            <button
              onClick={() => setIsAnnual(false)}
              className={`px-6 py-2 rounded-full text-sm font-medium transition-colors duration-200
                ${!isAnnual ? 'bg-primary text-white' : 'text-gray-600 hover:text-gray-900'}`}
            >
              Monthly
            </button>
            <button
              onClick={() => setIsAnnual(true)}
              className={`px-6 py-2 rounded-full text-sm font-medium transition-colors duration-200
                ${isAnnual ? 'bg-primary text-white' : 'text-gray-600 hover:text-gray-900'}`}
            >
              Annual
            </button>
          </div>
          {!showDeadstock && isAnnual && (
            <p className="text-sm text-primary mt-2">
              Get 1 month free with annual billing
            </p>
          )}
        </div>

        <div className={`grid gap-8 ${showDeadstock ? 'md:grid-cols-2 lg:grid-cols-4' : 'md:grid-cols-2 lg:grid-cols-4'}`}>
          {activePlans.map((plan, index) => (
            <motion.div
              key={plan.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`relative bg-white rounded-2xl shadow-sm hover:shadow-lg transition-shadow duration-200 
                ${plan.isPopular ? 'border-2 border-primary' : 'border border-gray-100'}`}
            >
              {plan.isPopular && (
                <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                  <span className="bg-primary text-white text-sm font-medium px-4 py-1 rounded-full">
                    Recommended
                  </span>
                </div>
              )}

              <div className="p-8">
                <div className="flex items-center gap-3 mb-6">
                  <span className="text-2xl">{plan.icon}</span>
                  <h3 className="text-xl font-semibold">{plan.name}</h3>
                </div>

                <div className="mb-6">
                  {renderPrice(plan)}
                  <p className="text-sm text-gray-600 mt-2">{plan.description}</p>
                </div>

                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-start gap-3">
                      <svg className="w-5 h-5 text-primary flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>

                <button 
                onClick={showDeadstock 
                  ? () => window.open("https://play.google.com/store/apps/details?id=com.app.wymbee", "_blank")
                  : plan.isEnterprise ? handleEnterpriseClick : undefined}
                className={`w-full py-3 px-6 rounded-lg font-medium transition-colors duration-200 
                  ${plan.isPopular 
                    ? 'bg-primary hover:bg-primary-hover text-white' 
                    : plan.isEnterprise
                      ? 'bg-gray-900 hover:bg-gray-800 text-white'
                      : 'bg-gray-50 hover:bg-gray-100 text-gray-900'}`}
                >
                {showDeadstock 
                  ? "Download App" 
                  : plan.isEnterprise ? "Let's Talk" : "Get started"}
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        <p className="text-center text-sm text-gray-600 mt-8">
          {showDeadstock 
            ? "Includes unlimited products, basic integrations, and priority support"
            : "All plans include unlimited conversations, basic integrations, and standard support"}
        </p>
      </div>

      <div className="container mx-auto px-4 md:px-8 mt-24">
        <div className="bg-gradient-to-br from-primary/5 via-primary/10 to-primary/5 rounded-3xl p-8 md:p-12">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <span className="inline-block text-primary font-medium px-4 py-1 bg-primary/10 rounded-full mb-6">
                Social Commerce Partner Program
              </span>
              <h2 className="text-3xl md:text-4xl font-bold mb-6">
                Earn Up to KES 105,000 
                <span className="block text-primary mt-2">Per Referral</span>
              </h2>
              <p className="text-gray-600 text-lg mb-8">
                Join our partner program and earn 25% commission for 6 months on every business you refer.
                No caps, quick payouts, and full support.
              </p>
              <Link
                to="/referral"
                className="inline-flex items-center gap-2 bg-primary hover:bg-primary-hover text-white px-8 py-3 rounded-lg font-medium transition-colors duration-200"
              >
                Become a Partner
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </Link>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="bg-white rounded-2xl p-6 shadow-xl relative"
            >
              <div className="absolute -top-3 -right-3 w-24 h-24 bg-primary/5 rounded-full blur-2xl" />
              <div className="relative">
                <div className="flex items-center gap-4 mb-8">
                  <div className="bg-primary/10 rounded-full p-3">
                    <svg className="w-6 h-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-semibold">Earning Example</h3>
                </div>
                
                <div className="space-y-6">
                  {[
                    { plan: "Basic Plan", commission: "KES 250/month" },
                    { plan: "Startup Plan", commission: "KES 1,250/month" },
                    { plan: "Growth Plan", commission: "KES 3,750/month" },
                    { plan: "Corporate", commission: "KES 17,500/month" },
                    { plan: "6-Month Duration", commission: "Per referral" }
                  ].map((item, index) => (
                    <div key={index} className="flex justify-between items-center border-b border-gray-100 pb-4">
                      <span className="text-gray-600">{item.plan}</span>
                      <span className="font-semibold text-primary">{item.commission}</span>
                    </div>
                  ))}
                </div>

                <p className="text-sm text-gray-500 mt-6">
                  *Example based on Growth plan referrals. Earnings vary based on plan type and duration.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}